/*
 *  Step component selecting the good StepEditor for each step
 */

import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { genAnchor } from 'utils/functions';

import messages from './StepEditors/messages';
import AddStep from './AddStep';
import {
  Casketing,
  Closing,
  Death,
  Interment,
  TransportAfterCasketing,
  Ceremony,
  Cremation,
  Repatriation,
  TransportBeforeCasketing,
  UrnPickup,
  Dispersion,
  UrnSealing,
  Deposit,
} from './StepEditors';

const stepComponents = {
  death: Death,
  casketing: Casketing,
  closing: Closing,
  deposit: Deposit,
  interment: Interment,
  transportAfterCasketing: TransportAfterCasketing,
  ceremony: Ceremony,
  cremation: Cremation,
  repatriation: Repatriation,
  transportBeforeCasketing: TransportBeforeCasketing,
  urnPickup: UrnPickup,
  dispersion: Dispersion,
  urnSealing: UrnSealing,
};

function Step(props) {
  const {
    crematorium,
    step,
    steps,
    wishes,
    ceremonies,
    funeralParlorStayTypes,
    updateStep,
    handleUpdateDeal,
    confirmDeleteStep,
    setFuneralParlorStayTypes,
    setAshesDestination,
    getServices,
    getStep,
    getFuneralParlorStayTypes,
    getCrematorium,
    getCeremonies,
    funeral,
    isManualMode,
    addService,
    userRole,
    updateService,
    getGraveyardBasicInfos,
    getGraveyardConcessionTypes,
    getGraveyardConcessions,
    graveyardBasicInfos,
    updateWish,
    findWish,
    removeService,
  } = props;
  const StepEditor = stepComponents[step.eventType];
  const dispatch = useDispatch();
  const intl = useIntl();

  return (
    <div id={genAnchor('step', step)}>
      <AddStep
        handleUpdateDeal={handleUpdateDeal}
        stepId={step.id}
        funeral={funeral}
      />
      <div className="step__title">
        <div className="steps__header">
          <FormattedMessage {...messages[step.eventType]} tagName="h3" />
        </div>
        <button
          type="button"
          className="step__title__delete"
          onClick={() => confirmDeleteStep(step.id, step.eventType, steps)}
        >
          ✕
        </button>
      </div>
      <div className="step__content">
        <StepEditor
          // eslint-disable-next-line
          {...props}
          intl={intl}
          dispatch={dispatch}
          handleChange={update => updateStep(update, step)}
          crematorium={crematorium}
          step={step}
          steps={steps}
          wishes={wishes}
          ceremonies={ceremonies}
          funeralParlorStayTypes={funeralParlorStayTypes}
          stepServices={getServices(step.id)}
          updateDelegate={checked =>
            updateStep({ doneByAnotherFuneral: checked }, step)
          }
          addService={addService}
          isManualMode={isManualMode}
          userRole={userRole}
          updateService={updateService}
          updateWish={updateWish}
          findWish={findWish}
          setFuneralParlorStayTypes={setFuneralParlorStayTypes}
          setAshesDestination={setAshesDestination}
          getStep={getStep}
          getFuneralParlorStayTypes={getFuneralParlorStayTypes}
          getGraveyardConcessionTypes={getGraveyardConcessionTypes}
          getGraveyardConcessions={getGraveyardConcessions}
          getGraveyardBasicInfos={getGraveyardBasicInfos}
          getCrematorium={getCrematorium}
          getCeremonies={getCeremonies}
          graveyardBasicInfos={graveyardBasicInfos}
          removeService={removeService}
        />
      </div>
    </div>
  );
}

Step.propTypes = {
  isNewDeal: PropTypes.bool.isRequired,
  handleUpdateDeal: PropTypes.func.isRequired,
  confirmDeleteStep: PropTypes.func.isRequired,
  findService: PropTypes.func,
  getServices: PropTypes.func.isRequired,
  crematorium: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  wishes: PropTypes.array.isRequired,
  ceremonies: PropTypes.array.isRequired,
  funeralParlorStayTypes: PropTypes.array.isRequired,
  updateStep: PropTypes.func.isRequired,
  funeral: PropTypes.object.isRequired,
  isManualMode: PropTypes.bool.isRequired,
  addService: PropTypes.func.isRequired,
  userRole: PropTypes.number.isRequired,
  updateWish: PropTypes.func.isRequired,
  updateService: PropTypes.func.isRequired,
  findWish: PropTypes.func.isRequired,
  setFuneralParlorStayTypes: PropTypes.func.isRequired,
  setAshesDestination: PropTypes.func.isRequired,
  getGraveyardBasicInfos: PropTypes.func.isRequired,
  getGraveyardConcessionTypes: PropTypes.func.isRequired,
  getGraveyardConcessions: PropTypes.func.isRequired,
  getStep: PropTypes.func.isRequired,
  getFuneralParlorStayTypes: PropTypes.func.isRequired,
  getCrematorium: PropTypes.func.isRequired,
  getCeremonies: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  graveyardBasicInfos: PropTypes.object.isRequired,
  deal: PropTypes.object,
  worshipTypes: PropTypes.array,
  concessionPrices: PropTypes.array,
  defunctAge: PropTypes.number,
  ConcessionTypes: PropTypes.object,
  coffin: PropTypes.object,
  user: PropTypes.object,
  getConcessionPrices: PropTypes.func,
  openDeleteStepDialogProps: PropTypes.func,
};

export default Step;
