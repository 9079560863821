import { ADDRESS_SKELETON } from './constants'
import type { Address } from './types'

export function formatAddressName(streetNumber: string | null, streetName: string | null): string {
  return [streetNumber || '', streetName || ''].join(' ').trim()
}

export function format(address: Address): string {
  const result: string[] = []

  const street = formatAddressName(address.street_number, address.street_name)
  if (street !== '') {
    result.push(street)
  }
  const city = [address.postal_code || '', address.city || ''].join(' ').trim()
  if (city !== '') {
    result.push(city)
  }
  if (address.country) {
    result.push(address.country)
  }

  return result.join(', ')
}

const componentMap: Readonly<Array<
  ['country' | 'city' | 'street_name' | 'street_number', string[]]
>> = [
  ['country', ['country']],
  ['city', ['locality']],
  ['street_name', ['route', 'colloquial_area']],
  ['street_number', ['street_number']],
]

function isComponentType(
  component: google.maps.GeocoderAddressComponent,
  types: string[],
): boolean {
  return component.types.some(type => types.includes(type))
}

function getComponent(
  components: google.maps.GeocoderAddressComponent[],
  componentType: string,
): google.maps.GeocoderAddressComponent | undefined {
  return components.find(component => isComponentType(component, [componentType]))
}

export function serialize(place: google.maps.GeocoderResult): Address {
  const address = { ...ADDRESS_SKELETON }

  let cursor = 0
  const components = [...place.address_components].reverse()
  components.forEach(component => {
    if (cursor < componentMap.length && isComponentType(component, componentMap[cursor][1])) {
      const property = componentMap[cursor][0]
      address[property] = component.long_name
      cursor += 1
    }
  })

  address.postal_code = getComponent(components, 'postal_code')?.long_name || null
  address.country_code = getComponent(components, 'country')?.short_name || null
  address.latitude = place.geometry.location.lat()
  address.longitude = place.geometry.location.lng()
  return address
}
