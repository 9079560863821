export const SIGNATURE_ENDED_MESSAGE_TYPE = 'success'

export const enum SignatureStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  REFUSED = 'refused',
  FINISHED = 'finished',
}

export type Message = {
  type: 'yousign'
  event: 'started' | 'success' | 'error' | 'declined' | 'ping' | 'signature.done'
}
