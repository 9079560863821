import { assert } from '@advitam/support'

export const Path = {
  // Website
  BLOG: '/guides-obseques',
  BLOG_ARTICLE: (category: string, slug: string): string => `${Path.BLOG}/${category}/${slug}`,
  BLOG_CATEGORY: (category: string): string => `${Path.BLOG}/${category}`,
  COMMITMENTS: '/a-propos-des-pompes-funebres-autrement',
  DOCUMENTS_SMS: (uuid: string): string => `/documents/sms/${uuid}`,
  FUNERAL_AT_PLACE: (dtpOrCity: string): string => `${Path.FUNERAL_INDEX}/${dtpOrCity}`,
  FUNERAL_INDEX: '/pompes-funebres',
  FUNERALS_QUOTE: '/devis-obseques',
  FUNERALS_QUOTE_CONFIRMATION: '/devis-obseques/confirmation',
  GRAVESTONE: '/pierre-tombale',
  GRAVESTONE_QUOTE: '/catalogue-pierres-tombales',
  GRAVESTONE_QUOTE_CONFIRMATION: '/catalogue-pierres-tombales/confirmation',
  FLOWERS_FUNNEL: '/fleurs-obseques',
  FLOWERS_FUNNEL_CONFIRMATION: '/fleurs-obseques/confirmation',
  HOME: '/',
  PAYMENT: '/paiement',
  PAYMENT_CONFIRMATION: (dealType: string): string => `/paiement/confirmation/${dealType}`,
  PRESS: '/presse',
  PROS_SPACE: '/professionnels-funeraire',
  QUOTE: '/devis',
  REPATRIATION_COUNTRY: (country: string): string => `${Path.REPATRIATION_INDEX}/${country}`,
  REPATRIATION_INDEX: '/rapatriement-corps-pays',
  REVIEWS: '/avis-advitam',
  SERVICES_EN_PLUS: '/nos-services-funeraires',

  // Static website
  GLOSSARY: '/lexique-obseques',

  // Fred
  MY_ACCOUNT: '/app/mon-compte',
  UPDATE_PASSWORD: '/app/changer-mot-de-passe',
  MEMORIAL: (memorialUrl: string): string => `/app/memoire/${memorialUrl}`,
}

export const I18nPath = {
  ar: {
    ADDITIONAL_SERVICES: '/exclusive-services',
    COMMITMENTS: '/about',
    FLOWERS_FUNNEL: '/funeral-flowers',
    FUNERAL_INDEX: '/funerals-france',
    FUNERALS_QUOTE: '/quote',
    GRAVESTONE: '/headstone-france',
    MY_ACCOUNT: '/app/my-account',
    PRESS: '/press',
    PROS_SPACE: '/funeral-director-france',
    REPATRIATION_INDEX: '/body-repatriation-france',
    FAMILY_REVIEWS: '/advitam-reviews',
  },
  de: {
    ADDITIONAL_SERVICES: '/exklusiven-dienstleistungen',
    COMMITMENTS: '/uber',
    FLOWERS_FUNNEL: '/bestattungsblumen',
    FUNERAL_INDEX: '/bestattungen-frankreich',
    FUNERALS_QUOTE: '/angebot',
    GRAVESTONE: '/grabstein-frankreich',
    MY_ACCOUNT: '/app/my-account',
    PRESS: '/press',
    PROS_SPACE: '/bestatter-frankreich',
    REPATRIATION_INDEX: '/leichenuberfuhrung-frankreich',
    FAMILY_REVIEWS: '/bewertungen-advitam',
  },
  en: {
    ADDITIONAL_SERVICES: '/exclusive-services',
    BLOG: '/howto-funeral',
    COMMITMENTS: '/about',
    FLOWERS_FUNNEL: '/funeral-flowers',
    FUNERAL_INDEX: '/funerals-france',
    FUNERALS_QUOTE: '/quote',
    GRAVESTONE: '/headstone-france',
    MY_ACCOUNT: '/app/my-account',
    PRESS: '/press',
    PROS_SPACE: '/funeral-director-france',
    REPATRIATION_INDEX: '/body-repatriation-france',
    FAMILY_REVIEWS: '/advitam-reviews',
  },
  es: {
    ADDITIONAL_SERVICES: '/servicios-exclusivos',
    COMMITMENTS: '/acerca',
    FLOWERS_FUNNEL: '/flores-funerarias',
    FUNERAL_INDEX: '/funerales-francia',
    FUNERALS_QUOTE: '/presupuesto',
    GRAVESTONE: '/lapida-francia',
    MY_ACCOUNT: '/app/my-account',
    PRESS: '/prensa',
    PROS_SPACE: '/funerarias-francia',
    REPATRIATION_INDEX: '/repatriacion-cuerpo-francia',
    FAMILY_REVIEWS: '/resenas-advitam',
  },
  fr: {
    ADDITIONAL_SERVICES: '/nos-services-funeraires',
    BLOG: '/guides-obseques',
    COMMITMENTS: '/a-propos-des-pompes-funebres-autrement',
    FLOWERS_FUNNEL: '/fleurs-obseques',
    FUNERAL_INDEX: '/pompes-funebres',
    FUNERALS_QUOTE: '/devis-obseques',
    GLOSSARY: '/lexique-obseques',
    GRAVESTONE: '/pierre-tombale',
    MY_ACCOUNT: '/app/mon-compte',
    PRESS: '/presse',
    PROS_SPACE: '/professionnels-funeraire',
    REPATRIATION_INDEX: '/rapatriement-corps-pays',
    FAMILY_REVIEWS: '/avis-advitam',
    ASSIST_GPT: '/assistgpt',
  },
  he: {
    ADDITIONAL_SERVICES: '/exclusive-services',
    COMMITMENTS: '/about',
    FLOWERS_FUNNEL: '/funeral-flowers',
    FUNERAL_INDEX: '/funerals-france',
    FUNERALS_QUOTE: '/quote',
    GRAVESTONE: '/headstone-france',
    MY_ACCOUNT: '/app/my-account',
    PRESS: '/press',
    PROS_SPACE: '/funeral-director-france',
    REPATRIATION_INDEX: '/body-repatriation-france',
    FAMILY_REVIEWS: '/advitam-reviews',
  },
  it: {
    ADDITIONAL_SERVICES: '/servizi-esclusivi',
    COMMITMENTS: '/azienda',
    FLOWERS_FUNNEL: '/fiori-funebri',
    FUNERAL_INDEX: '/funerali-francia',
    FUNERALS_QUOTE: '/preventivo',
    GRAVESTONE: '/lapide-francia',
    MY_ACCOUNT: '/app/my-account',
    PRESS: '/stampa',
    PROS_SPACE: '/onoranze-funebri-francia',
    REPATRIATION_INDEX: '/rimpatrio-salme-francia',
    FAMILY_REVIEWS: '/recensioni-advitam',
  },
  pt: {
    ADDITIONAL_SERVICES: '/servicos-exclusivos',
    COMMITMENTS: '/sobre',
    FLOWERS_FUNNEL: '/flores-funerarias',
    FUNERAL_INDEX: '/funerais-franca',
    FUNERALS_QUOTE: '/orcamento',
    GRAVESTONE: '/lapide-franca',
    MY_ACCOUNT: '/app/my-account',
    PRESS: '/imprensa',
    PROS_SPACE: '/agencia-funeraria-franca',
    REPATRIATION_INDEX: '/trasladacao-restos-mortais-franca',
    FAMILY_REVIEWS: '/revisoes-advitam',
  },
  sr: {
    ADDITIONAL_SERVICES: '/ekskluzivne-usluge',
    COMMITMENTS: '/o-nama',
    FLOWERS_FUNNEL: '/cvece-sahrane',
    FUNERAL_INDEX: '/sahrane-francuska',
    FUNERALS_QUOTE: '/budzet-pogreb',
    GRAVESTONE: '/nadgrobna-ploca-francuska',
    MY_ACCOUNT: '/app/my-account',
    PRESS: '/stampa',
    PROS_SPACE: '/pogrebne-usluge-francuska',
    REPATRIATION_INDEX: '/repatrijacija-tela-francuska',
    FAMILY_REVIEWS: '/recenzije-advitam',
  },
  tr: {
    ADDITIONAL_SERVICES: '/ozel-hizmetlerimiz',
    COMMITMENTS: '/hakkinda',
    FLOWERS_FUNNEL: '/cenaze-cicekleri',
    FUNERAL_INDEX: '/cenazeler-fransa',
    FUNERALS_QUOTE: '/alinti',
    GRAVESTONE: '/mezar-tasi-fransa',
    MY_ACCOUNT: '/app/my-account',
    PRESS: '/basinda',
    PROS_SPACE: '/cenazeciler-fransa',
    REPATRIATION_INDEX: '/cenaze-geri-donus-fransa',
    FAMILY_REVIEWS: '/advitam-incelemeleri',
  },
  zh: {
    ADDITIONAL_SERVICES: '/exclusive-services',
    COMMITMENTS: '/about',
    FLOWERS_FUNNEL: '/funeral-flowers',
    FUNERAL_INDEX: '/funerals-france',
    FUNERALS_QUOTE: '/quote',
    GRAVESTONE: '/headstone-france',
    MY_ACCOUNT: '/app/my-account',
    PRESS: '/press',
    PROS_SPACE: '/funeral-director-france',
    REPATRIATION_INDEX: '/body-repatriation-france',
    FAMILY_REVIEWS: '/advitam-reviews',
  },
}

assert(process.env.HOST !== undefined)
const GOOGLE_DRIVE = 'https://drive.google.com/'

export const Url = {
  AVIS_VERIFIES: 'https://www.avis-verifies.com/avis-clients/advitam.fr',
  FACEBOOK: 'https://www.facebook.com/advitam.official',
  GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
  PRESS_IMAGES: `${GOOGLE_DRIVE}open?id=1Csv8FQmzwc5-gSyQX3TLFpwexsCyWPmW`,
  PRESS_LOGOS: `${GOOGLE_DRIVE}open?id=1huEkkaZDnDGZDhZ6kv9izi4Ok09ZtEl8`,
  PRESS_TEAM: `${GOOGLE_DRIVE}open?id=1Aq_c6n4m6fbSMKG-oM8JjrvNna0_zxi6`,
  JOBS: 'https://advitam.welcomekit.co/',
  LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
  PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
  /** @warning: this is defined to the french website */
  SELF: process.env.HOST,
  X: 'https://x.com/_AdVitam',
  TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
}

export const I18nUrl = {
  ar: {
    FUNERALS_QUOTE: 'https://ar.advitam.fr/funeral-quote',
    GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
    LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
    PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
    TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
  },
  de: {
    FUNERALS_QUOTE: 'https://de.advitam.fr/beerdigung-angebot',
    GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
    LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
    PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
    TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
  },
  en: {
    FUNERALS_QUOTE: 'https://en.advitam.fr/funeral-quote',
    GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
    LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
    PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
    TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
  },
  es: {
    FUNERALS_QUOTE: 'https://es.advitam.fr/presupuesto-funeraria',
    GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
    LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
    PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
    TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
  },
  fr: {
    FUNERALS_QUOTE: `https://advitam.fr${Path.FUNERALS_QUOTE}`,
    GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
    LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
    PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
    TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
  },
  he: {
    FUNERALS_QUOTE: 'https://en.advitam.fr/funeral-quote',
    GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
    LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
    PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
    TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
  },
  it: {
    FUNERALS_QUOTE: 'https://it.advitam.fr/preventivo-funebre',
    GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
    LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
    PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
    TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
  },
  pt: {
    FUNERALS_QUOTE: 'https://pt.advitam.fr/cotacao-funebre',
    GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
    LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
    PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
    TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
  },
  sr: {
    FUNERALS_QUOTE: 'https://sr.advitam.fr/budzet-pogreb',
    GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
    LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
    PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
    TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
  },
  tr: {
    FUNERALS_QUOTE: 'https://tr.advitam.fr/cenaze-alinti',
    GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
    LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
    PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
    TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
  },
  zh: {
    FUNERALS_QUOTE: 'https://zh.advitam.fr/funeral-quote',
    GENERAL_DOCUMENTATION: 'https://advitam.fr/static/documentation/documentation-generale.pdf',
    LEGAL_NOTICE: 'https://advitam.fr/static/documentation/mentions-legales.pdf',
    PRIVACY_POLICY: 'https://advitam.fr/static/documentation/politique-confidentialite.pdf',
    TERMS: 'https://advitam.fr/static/documentation/CGS.pdf',
  },
}

export const Company = {
  PHONE_NUMBER: '+33977553939',
  FORMATTED_PHONE_NUMBER: '09 77 55 39 39',
  FORMATTED_FOREIGN_PHONE_NUMBER: '+33 9 77 55 39 39',
}
