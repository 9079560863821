import { ReactNode, useCallback } from 'react'
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form'

import NumberUI from '../UI/Number'
import { Number } from '../converters'
import { isRequired, useValidators, Validator } from '../validators'

interface InputProps {
  name: string
  label?: ReactNode
  placeholder?: string
  disabled?: boolean
  required?: boolean
  noscroll?: boolean
  withoutSpinner?: boolean
  readOnly?: boolean
  step?: number
  min?: number
  max?: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: UseFieldConfig<any>['parse']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: UseFieldConfig<any>['format']
  validate?: Validator<number>
  className?: string
  tooltip?: ReactNode
  prefix?: ReactNode
  suffix?: ReactNode
}

export default function Input({
  name,
  label,
  placeholder,
  disabled,
  required,
  noscroll,
  withoutSpinner,
  readOnly,
  step,
  min,
  max,
  parse = Number.parse,
  format = Number.format,
  validate,
  className,
  tooltip,
  prefix,
  suffix,
}: InputProps): JSX.Element {
  const render = useCallback(
    ({ input, meta }: FieldRenderProps<number>) => (
      <NumberUI
        label={label}
        onChange={input.onChange}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly as false}
        value={input.value}
        step={step}
        min={min}
        max={max}
        error={!!(meta.touched && meta.error)}
        className={className}
        tooltip={tooltip}
        prefix={prefix}
        suffix={suffix}
        noscroll={noscroll}
        withoutSpinner={withoutSpinner}
      />
    ),
    [
      className,
      disabled,
      label,
      max,
      min,
      noscroll,
      placeholder,
      prefix,
      readOnly,
      step,
      suffix,
      tooltip,
      withoutSpinner,
    ],
  )

  const validators = useValidators(required && isRequired, validate)

  return <Field name={name} validate={validators} parse={parse} format={format} render={render} />
}
