import { useCallback, useEffect } from 'react'

import { SIGNATURE_ENDED_MESSAGE_TYPE, Message } from './constants'

import style from './Yousign.module.scss'

interface YousignProps {
  onSigned: () => void
  signatureLink: string
  className?: string
  embedded?: boolean
}

export default function Yousign({
  onSigned,
  signatureLink,
  className,
  embedded,
}: YousignProps): JSX.Element | null {
  const onMessage = useCallback(
    (msg: MessageEvent<Message>) => {
      const { origin } = new URL(signatureLink)
      if (msg.origin !== origin) {
        return
      }

      if (msg.data.event === SIGNATURE_ENDED_MESSAGE_TYPE) {
        onSigned()
      }
    },
    [onSigned, signatureLink],
  )

  let src = signatureLink
  if (process.env.NODE_ENV === 'development') {
    src += '&disable_domain_validation=true'
  }

  useEffect(() => {
    window.addEventListener('message', onMessage)
    return (): void => window.removeEventListener('message', onMessage)
  }, [onMessage])

  const containerClassName = [style.container, embedded && style.embedded, className]
    .filter(Boolean)
    .join(' ')
  return (
    <div className={containerClassName}>
      <iframe className={style.frame} src={src} />
    </div>
  )
}
