import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { DealType } from '@advitam/api/models/Deal/Type';
import { FieldCard, Text } from '@advitam/ui';

import FuneralIcon from '@advitam/ui/images/illustrations/monument-cinerary.svg';
import MarbleIcon from '@advitam/ui/images/illustrations/monument-funeral.svg';
import ItemIcon from '@advitam/ui/images/illustrations/rose_2.svg';

import { initialize as initializeClients } from '../Clients/slice';
import { reset as resetAbilities } from '../Sections/Identity/slice';

import { setDeal, setDisplayStepsFactory } from '../slice';
import { DEAL_SKELETON } from './constants';
import messages from './messages';
import style from './TypeSelection.module.scss';

export default function TypeSelection(): JSX.Element {
  const dispatch = useDispatch();

  const onSelect = useCallback(
    (type: DealType): void => {
      if (type === DealType.FUNERAL) {
        dispatch(resetAbilities());
      } else {
        dispatch(initializeClients());
      }
      dispatch(setDeal(DEAL_SKELETON[type]));
      dispatch(setDisplayStepsFactory(true));
    },
    [dispatch],
  );

  return (
    <main className={style.container}>
      <div className={style.content}>
        <Text className={style.title}>
          <b>
            <FormattedMessage id={messages.chooseDealType.id} />
          </b>
        </Text>
        <div className={`col-10 ${style.cards}`}>
          <FieldCard.Button
            icon={<FuneralIcon className={style.icon} />}
            text={<FormattedMessage id={messages.funeral.id} />}
            className={style.card}
            onClick={(): void => onSelect(DealType.FUNERAL)}
          />
          <FieldCard.Button
            icon={<MarbleIcon className={style.icon} />}
            text={<FormattedMessage id={messages.marble.id} />}
            className={style.card}
            onClick={(): void => onSelect(DealType.MARBLE)}
          />
          <FieldCard.Button
            icon={<ItemIcon className={style.icon} />}
            text={<FormattedMessage id={messages.item.id} />}
            className={style.card}
            onClick={(): void => onSelect(DealType.ITEM)}
          />
        </div>
      </div>
    </main>
  );
}
