import { Subscription } from '@rails/actioncable';
import {
  Action,
  AsyncThunk,
  CaseReducer,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  Slice,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { assert } from '@advitam/support';

import {
  CableConfig,
  CableEvents,
  EventHandlersBuilder,
  MessageHandlersBuilder,
  MessageThunk,
} from './CableConfig';
import { subscribe } from './connection';
import { Action as CableAction } from './createAction';

/* eslint-disable @typescript-eslint/no-explicit-any */
type AnyThunk = AsyncThunk<any, any, any> & { subscription?: Subscription };
type Reducers<State> = Record<string, CaseReducer<State, PayloadAction<any>>>;
/* eslint-enable @typescript-eslint/no-explicit-any */

interface ConfiguredHandlers<State> {
  reducers: Reducers<State>;
  thunks: Record<string, AnyThunk>;
}

export interface Cable<InitParams> {
  slice: Slice;
  subscribe: (params: InitParams, api: ThunkConnection) => Subscription;
}

interface ThunkConnection {
  dispatch: ReturnType<typeof useDispatch>;
}

function getEventReducerName(event: string): string {
  // Prevent name clash with messages
  return `_event__${event}`;
}

function initSubscribe<InitParams, State>(
  config: CableConfig<State>,
  thunks: Record<string, AnyThunk>,
  slice: Slice,
): (params: InitParams, api: ThunkConnection) => Subscription {
  return (params: InitParams, { dispatch }: ThunkConnection): Subscription => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handle = (action: string, param: any): void => {
      const reducer = slice.actions[action];
      if (reducer) {
        dispatch(reducer(param));
      }
      const thunk = thunks[action];
      if (thunk) {
        dispatch((thunk(param) as unknown) as Action);
      }
    };

    const eventsHandlers: Reducers<State> = {};
    Object.values(CableEvents).forEach(event => {
      eventsHandlers[event] = (param): State | void => {
        handle(getEventReducerName(event), param);
      };
    });
    eventsHandlers.received = (data: CableAction): void => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      handle(data.action, data);
    };

    const subscription = subscribe(
      { ...params, channel: config.name },
      eventsHandlers,
    );
    Object.values(thunks).forEach(thunk => {
      // eslint-disable-next-line no-param-reassign
      thunk.subscription = subscription;
    });
    return subscription;
  };
}

function buildHandlers<State>(
  config: CableConfig<State>,
): ConfiguredHandlers<State> {
  const reducers: Reducers<State> = {};
  const thunks: Record<string, AnyThunk> = {};

  function addHandler<ActionType>(
    action: string,
    reducer: CaseReducer<State, PayloadAction<ActionType>> | null,
    thunk: MessageThunk<State, ActionType> | null,
  ): void {
    if (reducer) {
      reducers[action] = reducer;
    }
    if (thunk) {
      thunks[action] = createAsyncThunk(
        `ActionCable/${config.name}/${action}/send`,
        (payload, { dispatch, getState }) => {
          // Only set after subscription creation
          const sub = thunks[action].subscription;
          assert(sub !== undefined);

          return thunk({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            payload,
            dispatch,
            send: sub.send.bind(sub),
            getState,
          });
        },
      );
    }
  }

  if (config.events) {
    const builder: EventHandlersBuilder<State> = {
      addCase(action, reducer, thunk?): void {
        addHandler(getEventReducerName(action), reducer || null, thunk || null);
      },
    };
    config.events(builder);
  }

  if (config.messages) {
    const builder: MessageHandlersBuilder<State> = {
      addCase(action, reducer, thunk): void {
        addHandler(action.action, reducer || null, thunk || null);
      },
    };
    config.messages(builder);
  }
  return { reducers, thunks };
}

export function createCable<InitParams, State>(
  config: CableConfig<State>,
): Cable<InitParams> {
  const { reducers, thunks } = buildHandlers(config);

  const slice = createSlice({
    name: `ActionCable/${config.name}`,
    initialState: config.initialState,
    reducers,
  });

  return {
    slice,
    subscribe: initSubscribe<InitParams, State>(config, thunks, slice),
  };
}
