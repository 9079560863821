import { cloneDeep, mergeWith } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { assert } from '@advitam/support';
import { ApiError, SerializedApiError } from 'api';
import type { DealJSON } from 'models/Deal';
import { LegacyStep } from 'models/Deal/Step';
import type { BrandJSON } from 'models/Brand';

import { updateCommentary as updateCommentaryCompat } from './Commentaries/thunk';
import { updatePaymentProblem } from './Payments/thunk';
import { DEAL } from './constants';
import { fetchClients, fetchDeal, refreshDeal } from './thunk';
import { updateDealState as updateDealStateSidebar } from './SideBar/KoReasonModal/thunk';
import { updateOwner } from './Sections/Home/OwnerSelect/thunk';
import { updateBrand } from './Sections/Home/BrandSelect/thunk';
import { updateState } from './Sections/Home/StateSelect/thunk';
import { updateCommentary } from './Sections/Home/Commentaries/thunk';
import {
  createReminder,
  destroyReminder,
} from './Sections/Home/ReminderButton/thunk';

export interface State {
  deal: DealJSON | null;
  isDirty: boolean;
  isLoading: boolean;
  isSaving: boolean;
  /** Locks the UI, use `error` instead if a recovery / retry is possible. */
  fetchError: SerializedApiError | null;
  error: SerializedApiError | null;
  displayStepsFactory: boolean;
}

export interface AppStateSubset {
  [DEAL]: State;
}

export const initialState: State = {
  deal: null,
  isDirty: false, // TODO: It should be handled by the form
  isLoading: false,
  isSaving: false,
  fetchError: null,
  error: null,
  displayStepsFactory: false,
};

interface SetDealFieldsPayload {
  update: Partial<DealJSON>;
  isTouched?: boolean;
}

// Deep merge some fields in a deal
// Arrays are simply replaced, not concatenated
export function mergeDealFields(
  deal: DealJSON,
  source: Partial<DealJSON>,
): DealJSON {
  return mergeWith(cloneDeep(deal), source, (objValue, srcValue) => {
    if (Array.isArray(objValue)) {
      return srcValue;
    }
    return undefined;
  });
}

const slice = createSlice({
  name: DEAL,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setDeal(state, { payload }: PayloadAction<DealJSON | null>) {
      state.deal = payload;
      state.isDirty = false;
    },
    dealSaved(state, { payload }: PayloadAction<DealJSON | null>) {
      state.deal = payload;
      state.isDirty = false;
    },
    setDealBrand(state, { payload }: PayloadAction<BrandJSON>) {
      assert(state.deal !== null);
      state.deal.brand = payload;
    },
    setSteps(state, { payload }: PayloadAction<LegacyStep[]>) {
      assert(state.deal !== null && state.deal.funeral !== undefined);
      state.deal.funeral.steps = payload;
    },
    updateManualPrice(state, { payload }: PayloadAction<number | null>) {
      assert(state.deal !== null);
      state.deal.manual_price = payload;
      state.isDirty = true;
    },
    updateCeremonyDate(state, { payload }: PayloadAction<string | null>) {
      assert(state.deal !== null);
      const type = state.deal.deal_type;
      // TODO: Fix the model
      const details = (state.deal[type] as unknown) as {
        ceremony_dt: string | null;
      };
      details.ceremony_dt = payload;
      state.isDirty = true;
    },
    /** @deprecated */
    updateDeal(state, { payload }: PayloadAction<Partial<DealJSON>>) {
      assert(state.deal !== null);
      state.deal = {
        ...state.deal,
        ...payload,
      };
      state.isDirty = true;
    },
    setDealFields(state, { payload }: PayloadAction<SetDealFieldsPayload>) {
      assert(state.deal !== null);
      state.deal = mergeDealFields(state.deal, payload.update);
      if (payload.isTouched !== false) {
        state.isDirty = true;
      }
    },
    setDisplayStepsFactory(state, { payload }: PayloadAction<boolean>) {
      state.displayStepsFactory = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchDeal.pending, state => {
      state.isLoading = true;
      state.deal = null;
      state.fetchError = null;
    });
    builder.addCase(fetchDeal.fulfilled, state => {
      state.isLoading = false;
      state.displayStepsFactory = state.deal?.funeral?.steps.length === 0;
    });
    builder.addCase(fetchDeal.rejected, state => {
      state.isLoading = false;
    });

    builder.addCase(refreshDeal.pending, state => {
      state.fetchError = null;
    });
    builder.addCase(refreshDeal.fulfilled, (state, { payload }) => {
      state.deal = payload;
    });
    builder.addCase(refreshDeal.rejected, (state, { payload }) => {
      state.fetchError = ApiError.serialize(payload);
    });

    builder.addCase(fetchClients.rejected, (state, { payload }) => {
      state.fetchError = ApiError.serialize(payload);
    });

    builder.addCase(updatePaymentProblem.fulfilled, (state, { payload }) => {
      assert(state.deal !== null);
      state.deal.payment_problem = payload;
    });
    builder.addCase(updateCommentaryCompat.fulfilled, (state, { payload }) => {
      assert(state.deal !== null);
      state.deal.commentary = payload;
    });

    builder.addCase(updateOwner.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateOwner.fulfilled, (state, { payload }) => {
      assert(state.deal !== null);
      state.deal.user_id = payload.id;
      state.isSaving = false;
    });
    builder.addCase(updateOwner.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateBrand.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateBrand.fulfilled, state => {
      state.isSaving = false;
    });
    builder.addCase(updateBrand.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateState.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateState.fulfilled, (state, { meta }) => {
      assert(state.deal !== null);
      state.deal.state = meta.arg.state;
      state.deal.ko_comment = meta.arg.ko_comment || null;
      state.deal.ko_reason = meta.arg.ko_reason || null;
      state.isSaving = false;
    });
    builder.addCase(updateState.rejected, state => {
      state.isSaving = false;
    });

    builder.addCase(createReminder.fulfilled, (state, { payload }) => {
      assert(state.deal !== null);
      state.deal.remind_at = payload.remind_at;
    });
    builder.addCase(destroyReminder.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(destroyReminder.fulfilled, state => {
      assert(state.deal !== null);
      state.isSaving = false;
      state.deal.remind_at = null;
    });
    builder.addCase(destroyReminder.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateCommentary.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(updateCommentary.fulfilled, (state, { meta }) => {
      assert(state.deal !== null);
      state.deal.commentary = meta.arg;
      state.isSaving = false;
    });
    builder.addCase(updateCommentary.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(updateDealStateSidebar.pending, (state, { meta }) => {
      assert(state.deal !== null);
      state.deal.state = meta.arg.state;
      state.deal.ko_comment = meta.arg.ko_comment;
      state.deal.ko_reason = meta.arg.ko_reason;
    });
    builder.addCase(updateDealStateSidebar.fulfilled, (state, { meta }) => {
      assert(state.deal !== null);
      state.deal.state = meta.arg.state;
      state.deal.ko_comment = meta.arg.ko_comment;
      state.deal.ko_reason = meta.arg.ko_reason;
    });
    /* eslint-enable no-param-reassign */
  },
});

export const {
  setDeal,
  dealSaved,
  setDealBrand,
  setSteps,
  updateManualPrice,
  updateCeremonyDate,
  updateDeal,
  setDealFields,
  setDisplayStepsFactory,
} = slice.actions;
export default slice;
