import { ChangeEventHandler, InputHTMLAttributes, ReactNode } from 'react'

import Switch from '../../../Switch'
import Label from '../parts/Label'
import uiStyle from '../UI.module.scss'
import style from './BorderlessSwitch.module.scss'

interface BorderlessSwitchInputProps {
  value: InputHTMLAttributes<HTMLInputElement>['value']
  checked?: boolean
  label: ReactNode
  tooltip?: ReactNode
  name?: string
  error?: boolean
  disabled?: boolean
  className?: string
  onChange: ChangeEventHandler<HTMLInputElement>
}

export default function BorderlessSwitchInput({
  value,
  label,
  tooltip,
  name,
  onChange,
  checked = false,
  error = false,
  disabled = false,
  className = '',
}: BorderlessSwitchInputProps): JSX.Element {
  const classes = [
    style.field,
    checked && style.checked,
    error && style.error,
    disabled && uiStyle.disabled,
    className,
  ].filter(Boolean)

  return (
    <Label value={label} tooltip={tooltip} className={classes.join(' ')}>
      <Switch value={checked} />
      <input
        type="checkbox"
        name={name}
        value={value}
        onChange={disabled ? undefined : onChange}
        checked={checked}
      />
    </Label>
  )
}
