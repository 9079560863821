import type { ReactNode } from 'react'
import Frame from '../Frame'
import style from './Alert.module.scss'

interface AlertProps {
  children: ReactNode
  type: 'success' | 'warning' | 'error'
  className?: string
}

export default function Alert({ children, type, className }: AlertProps): JSX.Element {
  const classes = [style[type], className].filter(Boolean)

  return <Frame className={classes.join(' ')}>{children}</Frame>
}
