import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from 'api';
import { Deal, DealJSON } from 'models/Deal';
import type { AppStateSubset, State } from './slice';

import type { AppStateSubset as ClientsAppStateSubset } from './Clients/slice';
import { makeSelectIsDirty as makeSelectAreClientsDirty } from './Clients/selectors';
import { DEAL } from './constants';
import { AppStateSubset as IdentityAppStateSubset } from './Sections/Identity/slice';
import { makeSelectError as makeSelectIdentityError } from './Sections/Identity/selectors';

type DealSelector<T> = Selector<AppStateSubset, T>;

function selectDealDomain(state: AppStateSubset): State {
  return state[DEAL];
}

export function makeSelectRawDeal(): DealSelector<DealJSON | null> {
  return createSelector(selectDealDomain, state => state.deal);
}

export function makeSelectDeal(): DealSelector<Deal | null> {
  return createSelector(
    makeSelectRawDeal(),
    rawDeal => rawDeal && new Deal(rawDeal),
  );
}

export function makeSelectDealUuid(): DealSelector<string | null> {
  return createSelector(makeSelectRawDeal(), rawDeal => rawDeal?.uuid || null);
}

export function makeSelectIsLoading(): DealSelector<boolean> {
  return createSelector(selectDealDomain, state => state.isLoading);
}

export function makeSelectIsSaving(): DealSelector<boolean> {
  return createSelector(selectDealDomain, state => state.isSaving);
}

export function makeSelectFetchError(): DealSelector<SerializedApiError | null> {
  return createSelector(selectDealDomain, state => state.fetchError);
}

export function makeSelectError(): Selector<
  AppStateSubset & IdentityAppStateSubset,
  SerializedApiError | null
> {
  return createSelector(
    selectDealDomain,
    makeSelectIdentityError(),
    (state, abilitiesError) => state.error || abilitiesError,
  );
}

type DealAndClientSelector<T> = Selector<
  AppStateSubset & ClientsAppStateSubset,
  T
>;

export function makeSelectIsDirty(): DealAndClientSelector<boolean> {
  return createSelector(
    selectDealDomain,
    makeSelectAreClientsDirty(),
    (state, areClientsDirty) => state.isDirty || areClientsDirty,
  );
}

export function makeSelectDisplayStepsFactory(): DealSelector<boolean> {
  return createSelector(selectDealDomain, state => state.displayStepsFactory);
}
