import { ReactNode, useCallback, useState } from 'react'

import { useResizeObserver } from '@advitam/react'

import Tooltip from '../Tooltip'
import style from './CroppedText.module.scss'

interface CroppedTextProps {
  children: ReactNode
  tooltipClassName?: string
}

export default function CroppedText({ children, tooltipClassName }: CroppedTextProps): JSX.Element {
  const [isCropped, setIsCropped] = useState(false)
  const [containerRef, setContainerRef] = useState<HTMLElement | null>(null)
  const [hiddenRef, setHiddenRef] = useState<HTMLDivElement | null>(null)

  const onSizeChange = useCallback(() => {
    if (!containerRef || !hiddenRef) {
      return
    }

    const { clientWidth: containerWidth } = containerRef
    const { clientWidth: elementWidth } = hiddenRef
    setIsCropped(containerWidth < elementWidth)
  }, [setIsCropped, containerRef, hiddenRef])

  useResizeObserver(containerRef, onSizeChange)
  useResizeObserver(hiddenRef, onSizeChange)

  return (
    <>
      <div ref={setHiddenRef} className={style.hidden}>
        {children}
      </div>
      <Tooltip
        containerRef={setContainerRef}
        contentClassName={style.container}
        className={[style.tooltip, tooltipClassName].filter(Boolean).join(' ')}
        content={children}
        disabled={!isCropped}
      >
        {children}
      </Tooltip>
    </>
  )
}
