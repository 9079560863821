import { defineMessages } from 'react-intl';

export default defineMessages({
  deal__defunct__firstname__blank: {
    id: 'app.api.deal.defunct.firstname.blank',
  },
  deal__defunct__lastname__blank: { id: 'app.api.deal.defunct.lastname.blank' },
  deal__defunct__birth_date__blank: {
    id: 'app.api.deal.defunct.birthDate.blank',
  },
  deal__defunct__birth_location__blank: {
    id: 'app.api.deal.defunct.birthLocation.blank',
  },
  deal__defunct__birth_postal_code__blank: {
    id: 'app.api.deal.defunct.birthPostalCode.blank',
  },
  deal__defunct__birth_country_id__blank: {
    id: 'app.api.deal.defunct.birthCountryId.blank',
  },
  deal__defunct__address__blank: { id: 'app.api.deal.defunct.address.blank' },
  deal__defunct__postal_code__blank: {
    id: 'app.api.deal.defunct.postalCode.blank',
  },
  deal__defunct__city__blank: { id: 'app.api.deal.defunct.city.blank' },
  deal__defunct__country_id__blank: {
    id: 'app.api.deal.defunct.countryId.blank',
  },
  deal__defunct__death_date__blank: {
    id: 'app.api.deal.defunct.deathDate.blank',
  },
  deal__owner__firstname__blank: { id: 'app.api.deal.owner.firstname.blank' },
  deal__owner__lastname__blank: { id: 'app.api.deal.owner.lastname.blank' },
  deal__owner__email__blank: { id: 'app.api.deal.owner.email.blank' },
  deal__owner__phone__blank: { id: 'app.api.deal.owner.phone.blank' },
  deal__owner__phone_2__blank: { id: 'app.api.deal.owner.phone2.blank' },
  deal__owner__address__blank: { id: 'app.api.deal.owner.address.blank' },
  deal__owner__postal_code__blank: {
    id: 'app.api.deal.owner.postalCode.blank',
  },
  deal__owner__city__blank: { id: 'app.api.deal.owner.city.blank' },
  deal__owner__country_id__blank: { id: 'app.api.deal.owner.countryId.blank' },
  deal__step__date__future: { id: 'app.api.deal.step.date.future' },
  deal__validation__step_date_missing: { id: 'app.api.StepDateMissing' },
  deal__validation__step_dates_not_chronological: {
    id: 'app.api.StepDatesNotChronological',
  },
  deal__validation__step_dates_in_future: {
    id: 'app.api.StepDatesInFuture',
  },
  deal__validation__defunct_birth_date_missing: {
    id: 'app.api.DefunctBirthDateMissing',
  },
  deal__validation__defunct_info_missing: { id: 'app.api.DefunctInfoMissing' },
  deal__validation__defunct_firstname_missing: {
    id: 'app.api.FirstnameMissing',
  },
  deal__validation__defunct_lastname_missing: { id: 'app.api.LastnameMissing' },
  deal__validation__main_client_city_missing: {
    id: 'app.api.MainClientCityMissing',
  },
  deal__type__not_marble: { id: 'app.api.TypeNotMarble' },
  deal__type__not_item: { id: 'app.api.TypeNotItem' },
  deal__type__not_funeral: { id: 'app.api.TypeNotFuneral' },
  deal__state__not_changed: { id: 'app.api.StateNotChanged' },
  deal__ceremony_dt__blank: { id: 'app.api.CeremonyDateBlank' },
  deal__memorial__present: { id: 'app.api.MemorialPresent' },
  deal__memorial__blank: { id: 'app.api.MemorialBlank' },
  crud__validation__value_should_be_email: { id: 'app.api.ValueShouldBeEmail' },
  crud__validation__value_should_be_num: { id: 'app.api.ValueShouldBeNum' },
  crud__validation__key_is_missing: { id: 'app.api.KeyIsMissing' },
  crud__validation__value_should_be_json: { id: 'app.api.ValueShouldBeJSON' },
  crud__validation__value_should_be_boolean: {
    id: 'app.api.ValueShouldBeBool',
  },
  crud__validation__worship_not_found: { id: 'app.api.WorshipNotFound' },
  crud__validation__value_is_too_small: { id: 'app.api.ValueIsTooSmall' },
  crud__validation__invalid_value: { id: 'app.api.InvalidValue' },
  crud__validation__value_is_not_a_postal_code: {
    id: 'app.api.ValueIsNotAPostalCode',
  },
  crud__validation__value_is_not_a_phone_number: {
    id: 'app.api.ValueIsNotAPhoneNumber',
  },
  crud__validation__value_is_not_a_bool: { id: 'app.api.ValueIsNotABool' },
  crud__validation__value_is_not_a_department: {
    id: 'app.api.ValueIsNotADepartment',
  },
  crud__validation__value_is_too_big: { id: 'app.api.ValueIsTooBig' },
  crud__validation__value_is_too_long: { id: 'app.api.ValueIsTooLong' },
  crud__validation__value_is_too_short: { id: 'app.api.ValueIsTooShort' },
  crud__validation__value_is_not_an_array: { id: 'app.api.ValueIsNotAnArray' },
  crud__validation__invalid_hour_format: { id: 'app.api.InvalidHourFormat' },
  crud__validation__invalid_opening_hours_format: {
    id: 'app.api.InvalidOpeningHoursFormat',
  },
  crud__validation__postal_code_does_not_exist: {
    id: 'app.api.PostalCodeDoesNotExist',
  },
  crud__validation__invalid_country_code: { id: 'app.api.InvalidCountryCode' },
  crud__validation__value_is_not_an_insee_code: {
    id: 'app.api.ValueIsNotAnInseeCode',
  },
  crud__validation__insee_code_does_not_exist: {
    id: 'app.api.InseeCodeDoesNotExist',
  },
  crud__validation__invalid_rib: { id: 'app.api.InvalidRib' },
  crud__validation__invalid_iban: { id: 'app.api.InvalidIban' },
  crud__validation__invalid_siret: { id: 'app.api.InvalidSiret' },
  crud__validation__invalid_vat: { id: 'app.api.InvalidVat' },
  crud__validation__invalid_field: { id: 'app.api.InvalidField' },
  crud__validation__supplier_cyclic_dep: { id: 'app.api.supplierCyclicDep' },
  errorNotKnow: { id: 'app.api.errorNotKnow' },
  supplier_booking__deal_canceled: { id: 'app.api.dealCanceled' },
  supplier_booking__no_mobile_number: { id: 'app.api.noMobileNumber' },
  supplier_booking__transaction_closed: { id: 'app.api.transactionClosed' },
  supplier_booking__work_refused: { id: 'app.api.workRefused' },
  document_edition__document__file_too_big: { id: 'app.api.fileTooBig' },
  document_edition__document__unsupported_file_type: {
    id: 'app.api.unsupportedFileType',
  },
  document_edition__document__corrupted_file: { id: 'app.api.corruptedFile' },
  document_edition__document__unexpected_error: {
    id: 'app.api.unexpectedError',
  },
  supplier_warehouses__product__price__blank: {
    id: 'app.api.SupplierWarehouseProductPriceBlank',
  },
  supplier_warehouses__product__cost__blank: {
    id: 'app.api.SupplierWarehouseProductCostBlank',
  },
});
