import { useCallback, InputHTMLAttributes, ReactNode } from 'react'
import { Field, FieldRenderProps, UseFieldConfig } from 'react-final-form'

import { BorderlessSwitch } from '../UI'
import { isRequired, useValidators, Validator } from '../validators'

interface SwitchFieldProps {
  label: ReactNode
  tooltip?: ReactNode
  name: string
  value: InputHTMLAttributes<HTMLInputElement>['value']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parse?: UseFieldConfig<any>['parse']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: UseFieldConfig<any>['format']
  required?: boolean
  validate?: Validator<InputHTMLAttributes<HTMLInputElement>['value']>
  className?: string
  disabled?: boolean
}

export default function SwitchField({
  label,
  tooltip,
  name,
  value,
  parse,
  format,
  required,
  validate,
  className,
  disabled,
}: SwitchFieldProps): JSX.Element {
  const validators = useValidators(required && isRequired, validate)

  const render = useCallback(
    ({ input, meta }: FieldRenderProps<typeof value>): JSX.Element => (
      <BorderlessSwitch
        name={name}
        label={label}
        tooltip={tooltip}
        onChange={input.onChange}
        value={value}
        checked={input.checked}
        error={Boolean(meta.touched && meta.error)}
        className={className}
        disabled={disabled}
      />
    ),
    [name, label, tooltip, value, className, disabled],
  )

  return (
    <Field
      name={name}
      type="checkbox"
      value={value}
      parse={parse}
      format={format}
      validate={validators}
      render={render}
    />
  )
}
